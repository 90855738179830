"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wrapEffectCreator = wrapEffectCreator;
exports.like = like;
exports.isPartialMatcher = isPartialMatcher;

var _objectAssign = _interopRequireDefault(require("object-assign"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const PARTIAL_MATCH = '@@redux-saga-test-plan/partial-matcher';

function wrapEffectCreator(effectCreator) {
  return function wrappedEffectCreator(...args) {
    return effectCreator(...args);
  };
}

function like(providerKey, defaults = {}) {
  return function effectMatcher(effect) {
    return (0, _objectAssign.default)({}, defaults, {
      effect,
      providerKey,
      [PARTIAL_MATCH]: true
    });
  };
}

function isPartialMatcher(effect) {
  return PARTIAL_MATCH in effect;
}